var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "padding-bottom": "50px" } },
    [
      _c("header-title", { attrs: { title: "地图配置" } }),
      _c(
        "rs-form",
        {
          ref: "loginForm",
          staticStyle: { width: "70%" },
          attrs: {
            model: _vm.itemForm,
            "label-width": "140px",
            "show-message": false,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "地图类型：", prop: "type" },
            },
            [
              _c(
                "rs-radio-group",
                {
                  model: {
                    value: _vm.itemForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.itemForm, "type", $$v)
                    },
                    expression: "itemForm.type",
                  },
                },
                [
                  _c("rs-radio", { attrs: { label: "0" } }, [_vm._v("高德")]),
                  _c("rs-radio", { attrs: { label: "1" } }, [_vm._v("百度")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "KEY：", prop: "key" },
            },
            [
              _c("rs-input", {
                staticStyle: { width: "80%" },
                attrs: {
                  maxlength: "100",
                  placeholder: "请输入对应地图的key值",
                },
                model: {
                  value: _vm.itemForm.key,
                  callback: function ($$v) {
                    _vm.$set(_vm.itemForm, "key", $$v)
                  },
                  expression: "itemForm.key",
                },
              }),
            ],
            1
          ),
          _vm.itemForm.type != 1
            ? _c(
                "rs-form-item",
                {
                  staticClass: "form-item",
                  attrs: { label: "密钥：", prop: "secret" },
                },
                [
                  _c("rs-input", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      maxlength: "100",
                      placeholder: "请输入对应高德地图的密钥",
                    },
                    model: {
                      value: _vm.itemForm.secret,
                      callback: function ($$v) {
                        _vm.$set(_vm.itemForm, "secret", $$v)
                      },
                      expression: "itemForm.secret",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.jumpBack(false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }