<template>
  <div style="padding-bottom: 50px">
    <header-title :title="'地图配置'"></header-title>
    <rs-form :model="itemForm" label-width="140px" style="width: 70%" :show-message="false" :rules="rules" ref="loginForm" >
      <rs-form-item label="地图类型：" class="form-item" prop="type">
          <rs-radio-group v-model="itemForm.type">
            <rs-radio label="0">高德</rs-radio>
            <rs-radio label="1">百度</rs-radio>
          </rs-radio-group>
      </rs-form-item>
      <rs-form-item label="KEY：" class="form-item" prop="key">
        <rs-input v-model="itemForm.key" maxlength="100" placeholder="请输入对应地图的key值" style="width: 80%"></rs-input>
      </rs-form-item>
      <rs-form-item label="密钥：" class="form-item" prop="secret" v-if="itemForm.type!=1">
        <rs-input v-model="itemForm.secret" maxlength="100" placeholder="请输入对应高德地图的密钥" style="width: 80%"></rs-input>
      </rs-form-item>
    </rs-form>
    <div class="edit-form button-box" style="text-align: right">
      <rs-button size="small" @click="jumpBack(false)">取消</rs-button>
      <rs-button size="small" @click="save" type="primary">保存</rs-button>
    </div>
  </div>
</template>
<script>
import Api from '@/api/imgSetter.js'
import headerTitle from '@/components/header'
export default {
  components: { headerTitle },
  data() {
    return {
      type: 'system',
      title: '',
      saveLoading: false,
      itemForm: {
        type:'',
        key:'',
        secret:''
      },
      rules: {
        key: [{ required: true, message: '请输入对应地图的key值', trigger: 'blur' }],
        secret: [{ required: true, message: '请输入对应高德地图的密钥', trigger: 'blur' }],
      },
      uploadUrl: ''
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail(){
        let params = {configGroup:this.type=='system'?9:2}
        Api.findConfigByConfigGroup(params).then(res=>{
          if(res.data.code ==200){
            this.itemForm = res.data.data || {type:'0'}
          }
        })
    },
    jumpBack(flag) {
      this.$router.back()
    },
    save() {
       this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (!this.itemForm.type.length) {
            this.$message.warning('请选择地图类型！')
            return
          }
          let params = {
            configGroup: 9,
            config: { ...this.itemForm }
          }
          Api.saveConfig(params).then((res) => {
              if(res.data.code == 200){
                  this.$message.success('保存成功')
                   this.$router.back()
              }
          })
        }
      }) 
    }
  }
}
</script>
<style scoped lang="less">
.flexCls {
  display: flex;
  align-items: center;
}
.avatar-uploader /deep/ .rs-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader /deep/ .rs-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.avatar-uploader /deep/ .rs-button {
  height: 32px;
  line-height: 32px;
  padding: 0;
}
.rs-upload:hover .delCls {
  display: block;
}
.delCls {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  display: none;
}
.delCls i {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button-box {
  position: fixed;
}
.defaultImg {
  width: 120px;
  height: 120px;
  border: 1px solid #ddd;
}
.defaultImg img {
  width: 100%;
  height: 100%
}
.imgBox {
  width: 400px;
}
.imgBox img {
  width: 100%;
}
.uploadTip{
    font-size:12px;
}
</style>